import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import SuperadminLayout from './layouts/superadmin';
import AdminLayout from './layouts/admin';
import ManagerLayout from './layouts/manager';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Users from './pages/Users';
import AddEditUser from './pages/AddEditUser';
import TypeOfWorks from './pages/TypeOfWorks';
import Companies from './pages/Companies';
import AddEditCompany from './pages/AddEditCompany';
import Login from './pages/Login';
import Contractors from './pages/Contractors';
import Proposals from './pages/Proposals';
import Works from './pages/Works';
import WorkSchedule from './pages/WorkSchedule';
import PhysicalProgress from './pages/PhysicalProgress';
import FinancialProgress from './pages/FinancialProgress';
import QualityControl from './pages/QualityControl';
import Tenders from './pages/Tenders';
import AddEditContractor from './pages/AddEditContractor';
import AddEditTypeOfWorks from './pages/AddEditTypeOfWorks';
import AddEditProposal from './pages/AddEditProposal';
import AddEditTender from './pages/AddEditTender';
import AddEditWork from './pages/AddEditWork';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to="login" />,
    },
    {
      path: '/superadmin',
      element: <SuperadminLayout />,
      children: [
        { element: <Navigate to="dashboard" />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'companies', element: <Companies /> },
        { path: 'companies/add', element: <AddEditCompany /> },
        { path: 'companies/:company_id', element: <AddEditCompany /> },
        { path: 'users', element: <Users /> },
        { path: 'users/add', element: <AddEditUser /> },
        { path: 'users/:user_id', element: <AddEditUser /> },
        { path: 'profile', element: <Profile /> },
      ],
    },
    {
      path: '/admin',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="dashboard" />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'users', element: <Users /> },
        { path: 'users/add', element: <AddEditUser /> },
        { path: 'users/:user_id', element: <AddEditUser /> },
        { path: 'type_of_works', element: <TypeOfWorks /> },
        { path: 'type_of_works/add', element: <AddEditTypeOfWorks /> },
        { path: 'type_of_works/:type_of_work_id', element: <AddEditTypeOfWorks /> },
        { path: 'profile', element: <Profile /> },
      ],
    },
    {
      path: '/manager',
      element: <ManagerLayout />,
      children: [
        { element: <Navigate to="dashboard" />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'users', element: <Users /> },
        { path: 'users/add', element: <AddEditUser /> },
        { path: 'users/:user_id', element: <AddEditUser /> },
        { path: 'contractors', element: <Contractors /> },
        { path: 'contractors/add', element: <AddEditContractor /> },
        { path: 'contractors/:cont_id', element: <AddEditContractor /> },
        { path: 'proposals', element: <Proposals /> },
        { path: 'proposals/add', element: <AddEditProposal /> },
        { path: 'proposals/:prop_id', element: <AddEditProposal /> },
        { path: 'tenders', element: <Tenders /> },
        { path: 'tenders/add', element: <AddEditTender /> },
        { path: 'tenders/:tend_id', element: <AddEditTender /> },
        { path: 'works', element: <Works /> },
        { path: 'works/add', element: <AddEditWork /> },
        { path: 'works/:work_id', element: <AddEditWork /> },
        { path: 'work_schedule', element: <WorkSchedule /> },
        { path: 'physical_progress', element: <PhysicalProgress /> },
        { path: 'financial_progress', element: <FinancialProgress /> },
        { path: 'quality_control', element: <QualityControl /> },
        { path: 'profile', element: <Profile /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { element: <Navigate to="/dashboard/app" />, index: true },
    //     { path: '404', element: <Page404 /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
