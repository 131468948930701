module.exports = {
    // SERVER_BASE_URL: 'https://pms2-server.geopageconsultants.co.in/api',
    SERVER_BASE_URL: process.env.REACT_APP_SERVER_BASE_URL,
    APPLICATION_NAME: "PMS",
    APPLICATION_VERSION: "2.0.0",
    primaryColor: '#2065d1',
    primaryColorHighlighted: '#103996',
    toastDelay: 3000,
    currencyCode: 'INR',
    currencySymbol: '₹',
    ROLES: {
        SUPERADMIN: {
            key: 'superadmin',
            value: 50
        },
        ADMIN: {
            key: 'admin',
            value: 40
        },
        MANAGER: {
            key: 'manager',
            value: 35
        },
        USER: {
            key: 'user',
            value: 30
        }
    },
    CONTRACTOR_CLASSES: ['I', 'II', 'III', 'IV'],
    DATE_FORMAT: {
        MEDIUM: 'DD MMM YYYY',
    },
    BILL_TYPES: ["Running", "Final", "First and final"]
}