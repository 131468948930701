import { Helmet } from 'react-helmet-async';
import React from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  Box,
  Chip,
  InputAdornment,
  Pagination,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import i18n from 'src/i18n';
import config from 'src/config';
import { getContractors } from 'src/services/contractor.service';
import { StyledSearch } from 'src/components/searchbar/styles';
import Searchbar from 'src/components/searchbar/Searchbar';
import Swal from 'sweetalert2';
import Label from '../components/label';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import { convertMessageCodeToMessage } from 'src/utils/messageCodeToMessage';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'full_name', label: i18n.t('full_name'), alignRight: false, sortable: true },
  { id: 'contractor_id', label: i18n.t('contractor_id'), alignRight: false, sortable: true },
  { id: 'contractor_reg_no', label: i18n.t('contractor_reg_no'), alignRight: false, sortable: true },
  { id: 'class_of_contractor', label: i18n.t('class_of_contractor'), alignRight: false, sortable: true },
  { id: 'status', label: i18n.t('status'), alignRight: false, sortable: true },
  { id: '' },
];

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

class Contractors extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      contractors: [],
      open: null,
      page: 0,
      selected: [],
      searchValue: '',
      limit: 10,
      skip: 0,
      total_contractors: 0,
      sortOrder: 'asc',
      sortKey: 'full_name'
    };
  }

  async componentDidMount() {
    await this.setContractors();
  }

  async setContractors() {

    let query = {
        limit: this.state.limit,
        skip: this.state.skip,
    };
    
    if (this.state.searchValue) {
        query.searchKey = 'full_name,contractor_id,contractor_reg_no';
        query.searchValue = this.state.searchValue;
    }

    if (this.state.sortKey) {
        query.sortKey = this.state.sortKey;
        query.sortOrder = this.state.sortOrder ? this.state.sortOrder : 'asc';
    }

    let contractors = await getContractors(query);
    if (contractors.error) {
        Swal.fire({
            icon: 'error',
            title: i18n.t('error'),
            text: contractors.error_code ? convertMessageCodeToMessage(contractors.error_code) : contractors.error,
            confirmButtonText: i18n.t('ok'),
            confirmButtonColor: config.primaryColor
        });
        return;
    }

    this.setState({
        contractors: contractors?.contractors ? contractors.contractors : [],
        total_contractors: contractors?.count ? contractors.count : 0
    });
  }

  handleOpenMenu = (event) => {
    this.setState({open: event.currentTarget});
  };

  handleCloseMenu = () => {
    this.setState({open: null});
  };

  handleRequestSort = async (event, property) => {
    const isAsc = this.state.sortKey === property && this.state.sortOrder === 'asc';
    await this.setState({skip: 0, sortOrder: isAsc ? 'desc' : 'asc', sortKey: property});
    await this.setContractors();
  };

  handleClick = (event, name) => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(this.state.selected.slice(0, selectedIndex), this.state.selected.slice(selectedIndex + 1));
    }
    // setSelected(newSelected);
    this.setState({selected: newSelected});
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  render() {

    return (
      <>
        <Helmet>
          <title> {i18n.t('contractors')} | {config.APPLICATION_NAME} </title>
        </Helmet>

        <Container maxWidth="100%">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {i18n.t('contractors')}
            </Typography>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => {
                this.props.navigate('add');
              }}
            >
              {i18n.t('add_contractor')}
            </Button>
          </Stack>

          <Card>

            <Searchbar
                placeholder={i18n.t('search_contractors')}
                style={{margin: 20}}
                // sx={{ mb: 2 }}
                onChange={async (event) => {
                    await this.setState({
                        skip: 0,
                        searchValue: event.target.value
                    });
                    await this.setContractors();
                }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.alignRight ? 'right' : 'left'}
                          sortDirection={this.state.sortKey === headCell.id ? this.state.sortOrder : false}
                        >
                          {(headCell.sortable) ? (
                            <TableSortLabel
                              hideSortIcon={headCell.sortable ? false : true}
                              active={this.state.sortKey === headCell.id}
                              direction={this.state.sortKey === headCell.id ? this.state.sortOrder : 'asc'}
                              onClick={this.createSortHandler(headCell.id)}
                            >
                              {headCell.label}
                              {(this.state.sortKey === headCell.id && headCell.sortable) ? (
                                <Box sx={{ ...visuallyHidden }}>{this.state.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                              ) : null}
                            </TableSortLabel>
                          ) : (
                            <Typography variant="subtitle2">{headCell.label}</Typography>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.contractors?.map((row) => {
                      const { id, full_name, contractor_id, contractor_reg_no, class_of_contractor, status } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1}>

                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Stack direction="column" spacing={0} sx={{ typography: 'body2' }}>
                                <Typography variant="subtitle2">
                                  {full_name}
                                </Typography>
                              </Stack>
                            </Stack>
                          </TableCell>
                          
                          <TableCell align="left">{contractor_id}</TableCell>

                          <TableCell align="left">{contractor_reg_no}</TableCell>
                          
                          <TableCell align="left">{class_of_contractor}</TableCell>

                          <TableCell align="left">
                            {/* <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label> */}
                            <Label color={(status === 'active') ? 'success' : 'error'}>{i18n.t('STATUS.' + status)}</Label>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit"
                              onClick={(e) => {
                                this.setState({
                                  currentContractor: row
                                });
                                this.handleOpenMenu(e);
                              }}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>

                </Table>
              </TableContainer>
            </Scrollbar>

            <Stack spacing={2} sx={{alignItems: 'center'}}>
              <Pagination
                  count={Math.ceil(this.state.total_contractors / this.state.limit)}
                  shape="rounded"
                  size="large"
                  onChange={async (event, page) => {
                      await this.setState({
                          skip: (page - 1) * this.state.limit,
                      });
                      await this.setContractors();
                  }}
                  showFirstButton={true}
                  showLastButton={true}
                  boundaryCount={2}
                  style={{margin: 20}}
              />
            </Stack>

          </Card>
        </Container>

        <Popover
          open={Boolean(this.state.open)}
          anchorEl={this.state.open}
          onClose={this.handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              this.props.navigate( '/' + localStorage.getItem('role') + '/contractors/' + this.state.currentContractor.id);
              this.handleCloseMenu();
            }}
          >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            {i18n.t('edit')}
          </MenuItem>

          {/* <MenuItem sx={{ color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem> */}
        </Popover>

      </>
    );
  }
}

// Wrap and export
export default function(props) {
  const navigate = useNavigate();

  return <Contractors {...props} navigate={navigate} />;
}
