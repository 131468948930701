import axios from 'axios'
import config from '../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getTypeOfWorks = async (query=null) => {
    try {

        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/type_of_works' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getTypeOfWork = async (typeOfWorkId) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/type_of_works/' + typeOfWorkId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const addTypeOfWork = async (payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/type_of_works', payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const editTypeOfWork = async (typeOfWorkId, payload) => {
    try {
        let headers = getRequestHeaders();
        let response;
        try {
            response = await axios.patch( config.SERVER_BASE_URL + '/type_of_works/' + typeOfWorkId, payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getEventsByTypeOfWork = async (typeOfWork) => {
    try {
        let headers = getRequestHeaders();
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/type_of_works/' + typeOfWork + '/events', { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getTypeOfWorks,
    getTypeOfWork,
    addTypeOfWork,
    editTypeOfWork,
    getEventsByTypeOfWork
}