// component
import i18n from 'src/i18n';

import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: i18n.t('dashboard'),
    path: '/superadmin/dashboard',
    icon: <BarChartIcon  />,
  },
  {
    title: i18n.t('companies'),
    path: '/superadmin/companies',
    icon: <BusinessIcon  />,
  },
  {
    title: i18n.t('users'),
    path: '/superadmin/users',
    icon: <PeopleIcon />,
  }
];

export default navConfig;
