import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import { getProfile } from 'src/services/user.service';
import config from 'src/config';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

class ManagerLayout extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false
    }
  }

  async componentDidMount() {
    let token = localStorage.getItem('token');
    if (!token) {
      this.props.navigate('/login');
    }

    let profile = await getProfile();
    if(!profile || profile.error) {
      this.props.navigate('/login');
    }

    profile = profile?.profile;

    if(profile?.role !== config.ROLES.MANAGER.key) {
      this.props.navigate('/login');
    }
  }

  render() {
    return (
      <StyledRoot>
        <Header
          onOpenNav={() => {
            this.setState({ open: true })
          }}
        />

        <Nav openNav={this.state.open}
          onCloseNav={() => {
            this.setState({ open: false })
          }}
        />

        <Main>
          <Outlet />
        </Main>
      </StyledRoot>
    );
  }
}

export default function(props) {
  const navigate = useNavigate();

  return <ManagerLayout {...props} navigate={navigate} />;
}
