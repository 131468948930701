// component
import i18n from 'src/i18n';

import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleIcon from '@mui/icons-material/People';
import ConstructionIcon from '@mui/icons-material/Construction';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: i18n.t('dashboard'),
    path: '/admin/dashboard',
    icon: <BarChartIcon  />,
  },
  {
    title: i18n.t('users'),
    path: '/admin/users',
    icon: <PeopleIcon />,
  },
  {
    title: i18n.t('type_of_works'),
    path: '/admin/type_of_works',
    icon: <CalendarViewWeekIcon />,
  },
];

export default navConfig;
