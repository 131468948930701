import config from "src/config";

const getRequestHeaders = () => {
    try {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token')
        }
    } catch (error) {
        return null;
    }
}

const getQueryStringFromObject = (obj) => {
    let queryString = '';
    let queryStringArray = [];
    for (const [key, value] of Object.entries(obj)) {
        queryStringArray.push(`${key}=${value}`);
    }
    queryString = queryStringArray.join('&');
    return queryString;
}

const getRolesForSelection = (maxValue=null, minValue=5) => {
    let current_role_key = localStorage.getItem('role');

    if(!maxValue) {
        let temp = Object.keys(config.ROLES).find(role => config.ROLES[role].key === current_role_key);
        let current_role = config.ROLES[temp];
        maxValue = current_role?.value;
    }

    let roles = [];

    if (!maxValue) {
        return roles;
    }

    for (const [key, value] of Object.entries(config.ROLES)) {
        if(value.value < maxValue && value.value >= minValue) {
            roles.push({
                id: value.key,
                label: value.key
            });
        }
    }
    return roles;
}

const getRoleValueFromRole = (role) => {
    let temp = Object.keys(config.ROLES).find(config_role => config.ROLES[config_role].key === role);
    let current_role = config.ROLES[temp];
    return current_role?.value;
}

const getContractorClassesForSelection = () => {
    let contractor_classes = [];
    for (const contractor_class of config.CONTRACTOR_CLASSES) {
        contractor_classes.push({
            id: contractor_class,
            label: contractor_class
        });
    }
    return contractor_classes;
}

export {
    getRequestHeaders,
    getQueryStringFromObject,
    getRolesForSelection,
    getRoleValueFromRole,
    getContractorClassesForSelection
}