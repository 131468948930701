// component
import i18n from 'src/i18n';

import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleIcon from '@mui/icons-material/People';
import ConstructionIcon from '@mui/icons-material/Construction';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import BusinessIcon from '@mui/icons-material/Business';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: i18n.t('dashboard'),
    path: '/manager/dashboard',
    icon: <BarChartIcon  />,
  },
  {
    title: i18n.t('users'),
    path: '/manager/users',
    icon: <PeopleIcon />,
  },
  {
    title: i18n.t('contractors'),
    path: '/manager/contractors',
    icon: <ConstructionIcon />,
  },
  {
    title: i18n.t('proposals'),
    path: '/manager/proposals',
    icon: <CalendarViewWeekIcon />,
  },
  {
    title: i18n.t('tenders'),
    path: '/manager/tenders',
    icon: <CalendarViewWeekIcon />,
  },
  {
    title: i18n.t('works'),
    path: '/manager/works',
    icon: <CalendarViewWeekIcon />,
  },
  {
    title: i18n.t('work_schedule'),
    path: '/manager/work_schedule',
    icon: <CalendarViewWeekIcon />,
  },
  {
    title: i18n.t('physical_progress'),
    path: '/manager/physical_progress',
    icon: <CalendarViewWeekIcon />,
  },
  {
    title: i18n.t('financial_progress'),
    path: '/manager/financial_progress',
    icon: <CalendarViewWeekIcon />,
  },
  {
    title: i18n.t('quality_control'),
    path: '/manager/quality_control',
    icon: <CalendarViewWeekIcon />,
  },
];

export default navConfig;
